import {
  OwnerPropertyCardType,
  PropertyActiveProcessCardType,
} from "@/enums/PropertyCardType";
import { PropertyStatus } from "@/enums/PropertyStatus";
import { RentalLeasingStatus } from "@/enums/RentalLeasing";
import OwnerCardsMappingService from "@/services/OwnerCardsMappingService";
import { IRentalLeasing } from "@/models/RentalLeasing";

describe("OwnerCardsMappingService", () => {
  beforeEach(() => {
    jest.restoreAllMocks();
  });

  describe("getCardType", () => {
    it("Should return card type CAPTURED_WITH_ACTIVE_LEASING", () => {
      // given
      const leasings = [
        {
          leasingId: 1,
          status: RentalLeasingStatus.PENDING_EXIT,
          lastStatusChangedAt: "",
        },
      ];
      const result = OwnerCardsMappingService.getCardType(
        PropertyStatus.CAPTURED,
        leasings
      );
      // when
      // then
      expect(result).toEqual(
        OwnerPropertyCardType.CAPTURED_WITH_ACTIVE_LEASING
      );
    });
    it("Should return card type ONBOARDING", () => {
      // given
      const leasings = [] as IRentalLeasing[];
      const result = OwnerCardsMappingService.getCardType(
        PropertyStatus.CAPTURED,
        leasings
      );
      // when
      // then
      expect(result).toEqual(OwnerPropertyCardType.ONBOARDING);
    });
    it("Should return card type PUBLISHED_WITH_ACTIVE_LEASING", () => {
      const leasings = [
        {
          leasingId: 1,
          status: RentalLeasingStatus.PENDING_EXIT,
          lastStatusChangedAt: "",
        },
      ];
      const result = OwnerCardsMappingService.getCardType(
        PropertyStatus.PUBLISHED,
        leasings
      );
      // when
      // then
      expect(result).toEqual(
        OwnerPropertyCardType.PUBLISHED_WITH_ACTIVE_LEASING
      );
    });
    it("Should return card type PUBLISHED", () => {
      const leasings = [] as IRentalLeasing[];
      const result = OwnerCardsMappingService.getCardType(
        PropertyStatus.PUBLISHED,
        leasings,
        true
      );
      // when
      // then
      expect(result).toEqual(OwnerPropertyCardType.PUBLISHED);
    });
    it("Should return card type ONBOARDING", () => {
      const leasings = [] as IRentalLeasing[];
      const result = OwnerCardsMappingService.getCardType(
        PropertyStatus.PUBLISHED,
        leasings
      );
      // when
      // then
      expect(result).toEqual(OwnerPropertyCardType.ONBOARDING);
    });
    it("Should return card type PENDING_SIGNATURE", () => {
      const leasings = [
        {
          leasingId: 1,
          status: RentalLeasingStatus.PENDING_EXIT,
          lastStatusChangedAt: "",
        },
        {
          leasingId: 1,
          status: RentalLeasingStatus.PENDING_ENTRY,
          lastStatusChangedAt: "",
        },
      ];
      const result = OwnerCardsMappingService.getCardType(
        PropertyStatus.RENTAL_MANAGEMENT,
        leasings
      );
      // when
      // then
      expect(result).toEqual(OwnerPropertyCardType.PENDING_SIGNATURE);
    });
    it("Should return card type PENDING_SIGNATURE", () => {
      const leasings = [
        {
          leasingId: 1,
          status: RentalLeasingStatus.PENDING_EXIT,
          lastStatusChangedAt: "",
        },
        {
          leasingId: 1,
          status: RentalLeasingStatus.PENDING_CONFIRMATION,
          lastStatusChangedAt: "",
        },
      ];
      const result = OwnerCardsMappingService.getCardType(
        PropertyStatus.RENTAL_MANAGEMENT,
        leasings
      );
      // when
      // then
      expect(result).toEqual(OwnerPropertyCardType.PENDING_SIGNATURE);
    });
    it("Should return card type RESIGNATION", () => {
      const leasings = [
        {
          leasingId: 1,
          status: RentalLeasingStatus.PENDING_EXIT,
          lastStatusChangedAt: "",
        },
      ];
      const result = OwnerCardsMappingService.getCardType(
        PropertyStatus.RENTAL_MANAGEMENT,
        leasings
      );
      // when
      // then
      expect(result).toEqual(OwnerPropertyCardType.RESIGNATION);
    });
    it("Should return card type RESERVATION", () => {
      const leasings = [
        {
          leasingId: 1,
          status: RentalLeasingStatus.PENDING_CONFIRMATION,
          lastStatusChangedAt: "",
        },
      ];
      const result = OwnerCardsMappingService.getCardType(
        PropertyStatus.RENTAL_MANAGEMENT,
        leasings
      );
      // when
      // then
      expect(result).toEqual(OwnerPropertyCardType.RESERVATION);
    });
    it("Should return card type RESERVATION", () => {
      const leasings = [
        {
          leasingId: 1,
          status: RentalLeasingStatus.PENDING_ENTRY,
          lastStatusChangedAt: "",
        },
      ];
      const result = OwnerCardsMappingService.getCardType(
        PropertyStatus.RENTAL_MANAGEMENT,
        leasings
      );
      // when
      // then
      expect(result).toEqual(OwnerPropertyCardType.RESERVATION);
    });
    it("Should return card type IN_MANAGEMENT", () => {
      const leasings = [
        {
          leasingId: 1,
          status: RentalLeasingStatus.WON,
          lastStatusChangedAt: "",
        },
      ];
      const result = OwnerCardsMappingService.getCardType(
        PropertyStatus.RENTAL_MANAGEMENT,
        leasings
      );
      // when
      // then
      expect(result).toEqual(OwnerPropertyCardType.IN_MANAGEMENT);
    });
    it("Should return card type IN_MANAGEMENT", () => {
      const leasings = [
        {
          leasingId: 1,
          status: RentalLeasingStatus.ONGOING,
          lastStatusChangedAt: "",
        },
      ];
      const result = OwnerCardsMappingService.getCardType(
        PropertyStatus.RENTAL_MANAGEMENT,
        leasings
      );
      // when
      // then
      expect(result).toEqual(OwnerPropertyCardType.IN_MANAGEMENT);
    });
    it("Should return card type NOT_RENTED", () => {
      const leasings = [
        {
          leasingId: 1,
          status: RentalLeasingStatus.LOST,
          lastStatusChangedAt: "",
        },
      ];
      const result = OwnerCardsMappingService.getCardType(
        PropertyStatus.RENTAL_MANAGEMENT,
        leasings
      );
      // when
      // then
      expect(result).toEqual(OwnerPropertyCardType.NOT_RENTED);
    });
    it("Should return card type CANCELLED", () => {
      const leasings = [] as IRentalLeasing[];
      const result = OwnerCardsMappingService.getCardType(
        PropertyStatus.LOST,
        leasings
      );
      // when
      // then
      expect(result).toEqual(OwnerPropertyCardType.CANCELLED);
    });
    it("Should return card type CANCELLED", () => {
      const leasings = [] as IRentalLeasing[];
      const result = OwnerCardsMappingService.getCardType(
        PropertyStatus.LOST_NOT_PUBLISHED,
        leasings
      );
      // when
      // then
      expect(result).toEqual(OwnerPropertyCardType.CANCELLED);
    });
    it("Should return card type PENDING_RECOVER with no date", () => {
      const result = OwnerCardsMappingService.getCardType(
        PropertyStatus.PENDING_RECOVER,
        []
      );
      // when
      // then
      expect(result).toEqual(OwnerPropertyCardType.PENDING_RECOVER);
    });
    it("Should return card type PENDING_RECOVER with past date", () => {
      const rentalPublishingDate = "1999-12-31";
      const result = OwnerCardsMappingService.getCardType(
        PropertyStatus.PENDING_RECOVER,
        [],
        false,
        rentalPublishingDate
      );
      // when
      // then
      expect(result).toEqual(OwnerPropertyCardType.PENDING_RECOVER);
    });
    it("Should return card type PENDING_RECOVER with past date", () => {
      const rentalPublishingDate = new Date().toISOString();
      const result = OwnerCardsMappingService.getCardType(
        PropertyStatus.PENDING_RECOVER,
        [],
        false,
        rentalPublishingDate
      );
      // when
      // then
      expect(result).toEqual(OwnerPropertyCardType.PENDING_RECOVER);
    });
    it("Should return card type PENDING_RECOVER_FUTURE_DATE with future date", () => {
      const rentalPublishingDate = "2999-12-31";
      const result = OwnerCardsMappingService.getCardType(
        PropertyStatus.PENDING_RECOVER,
        [],
        false,
        rentalPublishingDate
      );
      // when
      // then
      expect(result).toEqual(OwnerPropertyCardType.PENDING_RECOVER_FUTURE_DATE);
    });
  });

  describe("getActiveProcessCardTypes", () => {
    it("Should return active process card type RESIGNATION", () => {
      // given
      const leasings = [
        {
          leasingId: 1,
          status: RentalLeasingStatus.PENDING_EXIT,
          lastStatusChangedAt: "",
        },
      ];
      const result = OwnerCardsMappingService.getActiveProcessCardTypes(
        PropertyStatus.CAPTURED,
        leasings
      );
      // when
      // then
      expect(result).toEqual(
        expect.arrayContaining([PropertyActiveProcessCardType.RESIGNATION])
      );
    });
    it("Should return active process card type CAPTURED", () => {
      // given
      const leasings = [] as IRentalLeasing[];
      const result = OwnerCardsMappingService.getActiveProcessCardTypes(
        PropertyStatus.CAPTURED,
        leasings
      );
      // when
      // then
      expect(result).toEqual(
        expect.arrayContaining([PropertyActiveProcessCardType.CAPTURED])
      );
    });
    it("Should return active process card type PUBLISHED when property is published & visible", () => {
      // given
      const propertyIsVisible = true;
      const leasings = [] as IRentalLeasing[];
      const result = OwnerCardsMappingService.getActiveProcessCardTypes(
        PropertyStatus.PUBLISHED,
        leasings,
        propertyIsVisible
      );
      // when
      // then
      expect(result).toEqual(
        expect.arrayContaining([PropertyActiveProcessCardType.PUBLISHED])
      );
    });
    it("Should return active process card type CAPTURED_NOT_CLICKABLE when property is published & not visible", () => {
      // given
      const propertyIsVisible = false;
      const leasings = [] as IRentalLeasing[];
      const result = OwnerCardsMappingService.getActiveProcessCardTypes(
        PropertyStatus.PUBLISHED,
        leasings,
        propertyIsVisible
      );
      // when
      // then
      expect(result).toEqual(
        expect.arrayContaining([
          PropertyActiveProcessCardType.CAPTURED_NOT_CLICKABLE,
        ])
      );
    });
    it("Should return active process card type PENDING_SIGNATURE", () => {
      const leasings = [
        {
          leasingId: 1,
          status: RentalLeasingStatus.PENDING_ENTRY,
          lastStatusChangedAt: "",
        },
      ];
      const result = OwnerCardsMappingService.getActiveProcessCardTypes(
        PropertyStatus.RENTAL_MANAGEMENT,
        leasings
      );
      // when
      // then
      expect(result).toEqual(
        expect.arrayContaining([
          PropertyActiveProcessCardType.PENDING_SIGNATURE,
        ])
      );
    });
    it("Should return active process card type PENDING_SIGNATURE", () => {
      const leasings = [
        {
          leasingId: 1,
          status: RentalLeasingStatus.PENDING_EXIT,
          lastStatusChangedAt: "",
        },
        {
          leasingId: 2,
          status: RentalLeasingStatus.PENDING_CONFIRMATION,
          lastStatusChangedAt: "",
        },
      ];
      const result = OwnerCardsMappingService.getActiveProcessCardTypes(
        PropertyStatus.RENTAL_MANAGEMENT,
        leasings
      );
      // when
      // then
      expect(result).toEqual(
        expect.arrayContaining([
          PropertyActiveProcessCardType.PENDING_SIGNATURE,
        ])
      );
    });
    it("Should return active process card type IN_MANAGEMENT", () => {
      const leasings = [
        {
          leasingId: 1,
          status: RentalLeasingStatus.WON,
          lastStatusChangedAt: "",
        },
      ];
      const result = OwnerCardsMappingService.getActiveProcessCardTypes(
        PropertyStatus.RENTAL_MANAGEMENT,
        leasings
      );
      // when
      // then
      expect(result).toEqual(
        expect.arrayContaining([PropertyActiveProcessCardType.IN_MANAGEMENT])
      );
    });
    it("Should return active process card type IN_MANAGEMENT", () => {
      const leasings = [
        {
          leasingId: 1,
          status: RentalLeasingStatus.ONGOING,
          lastStatusChangedAt: "",
        },
      ];
      const result = OwnerCardsMappingService.getActiveProcessCardTypes(
        PropertyStatus.RENTAL_MANAGEMENT,
        leasings
      );
      // when
      // then
      expect(result).toEqual(
        expect.arrayContaining([PropertyActiveProcessCardType.IN_MANAGEMENT])
      );
    });
    it("Should return active process card type NOT_RENTED", () => {
      const leasings = [
        {
          leasingId: 1,
          status: RentalLeasingStatus.LOST,
          lastStatusChangedAt: "",
        },
      ];
      const result = OwnerCardsMappingService.getActiveProcessCardTypes(
        PropertyStatus.RENTAL_MANAGEMENT,
        leasings
      );
      // when
      // then
      expect(result).toEqual(
        expect.arrayContaining([PropertyActiveProcessCardType.NOT_RENTED])
      );
    });
    it("Should return active process card type CANCELLED", () => {
      const leasings = [] as IRentalLeasing[];
      const result = OwnerCardsMappingService.getActiveProcessCardTypes(
        PropertyStatus.LOST,
        leasings
      );
      // when
      // then
      expect(result).toEqual(
        expect.arrayContaining([PropertyActiveProcessCardType.CANCELLED])
      );
    });
    it("Should return active process card type CANCELLED", () => {
      const leasings = [] as IRentalLeasing[];
      const result = OwnerCardsMappingService.getActiveProcessCardTypes(
        PropertyStatus.LOST_NOT_PUBLISHED,
        leasings
      );
      // when
      // then
      expect(result).toEqual(
        expect.arrayContaining([PropertyActiveProcessCardType.CANCELLED])
      );
    });
    it("Should return active process card type NOT_RENTED", () => {
      const leasings: IRentalLeasing[] = [];
      const result = OwnerCardsMappingService.getActiveProcessCardTypes(
        PropertyStatus.RENTAL_MANAGEMENT,
        leasings
      );
      // when
      // then
      expect(result).toEqual(
        expect.arrayContaining([PropertyActiveProcessCardType.NOT_RENTED])
      );
    });
    it("Should return active process card type PENDING_RECOVER with no date", () => {
      const result = OwnerCardsMappingService.getActiveProcessCardTypes(
        PropertyStatus.PENDING_RECOVER,
        []
      );
      // when
      // then
      expect(result).toEqual(
        expect.arrayContaining([PropertyActiveProcessCardType.PENDING_RECOVER])
      );
    });
    it("Should return active process card type PENDING_RECOVER with past date", () => {
      const rentalPublishingDate = "1999-12-31";
      const result = OwnerCardsMappingService.getActiveProcessCardTypes(
        PropertyStatus.PENDING_RECOVER,
        [],
        false,
        rentalPublishingDate
      );
      // when
      // then
      expect(result).toEqual(
        expect.arrayContaining([PropertyActiveProcessCardType.PENDING_RECOVER])
      );
    });
    it("Should return active process card type PENDING_RECOVER with past date", () => {
      const rentalPublishingDate = new Date().toISOString();
      const result = OwnerCardsMappingService.getActiveProcessCardTypes(
        PropertyStatus.PENDING_RECOVER,
        [],
        false,
        rentalPublishingDate
      );
      // when
      // then
      expect(result).toEqual(
        expect.arrayContaining([PropertyActiveProcessCardType.PENDING_RECOVER])
      );
    });
    it("Should return active process card type PENDING_RECOVER_FUTURE_DATE with future date", () => {
      const rentalPublishingDate = "2999-12-31";
      const result = OwnerCardsMappingService.getActiveProcessCardTypes(
        PropertyStatus.PENDING_RECOVER,
        [],
        false,
        rentalPublishingDate
      );
      // when
      // then
      expect(result).toEqual(
        expect.arrayContaining([
          PropertyActiveProcessCardType.PENDING_RECOVER_FUTURE_DATE,
        ])
      );
    });
  });

  describe("hasSomeLeasingType", () => {
    it("Should return true for every leasing type", () => {
      // given
      const leasings = [
        {
          leasingId: 1,
          status: RentalLeasingStatus.PENDING_CONFIRMATION,
          lastStatusChangedAt: "",
        },
        {
          leasingId: 1,
          status: RentalLeasingStatus.PENDING_ENTRY,
          lastStatusChangedAt: "",
        },
        {
          leasingId: 1,
          status: RentalLeasingStatus.PENDING_EXIT,
          lastStatusChangedAt: "",
        },
        {
          leasingId: 1,
          status: RentalLeasingStatus.ONGOING,
          lastStatusChangedAt: "",
        },
        {
          leasingId: 1,
          status: RentalLeasingStatus.WON,
          lastStatusChangedAt: "",
        },
        {
          leasingId: 1,
          status: RentalLeasingStatus.LOST,
          lastStatusChangedAt: "",
        },
      ];
      const result = OwnerCardsMappingService.hasSomeLeasingType(leasings);
      // when
      // then
      expect(result).toEqual({
        [RentalLeasingStatus.PENDING_ENTRY]: true,
        [RentalLeasingStatus.ONGOING]: true,
        [RentalLeasingStatus.PENDING_EXIT]: true,
        [RentalLeasingStatus.LOST]: true,
        [RentalLeasingStatus.PENDING_CONFIRMATION]: true,
        [RentalLeasingStatus.WON]: true,
      });
    });
    it("Should return false for every leasing typ", () => {
      // given
      const leasings = [] as IRentalLeasing[];
      const result = OwnerCardsMappingService.hasSomeLeasingType(leasings);
      // when
      // then
      expect(result).toEqual({
        [RentalLeasingStatus.PENDING_ENTRY]: false,
        [RentalLeasingStatus.ONGOING]: false,
        [RentalLeasingStatus.PENDING_EXIT]: false,
        [RentalLeasingStatus.LOST]: false,
        [RentalLeasingStatus.PENDING_CONFIRMATION]: false,
        [RentalLeasingStatus.WON]: false,
      });
    });
  });
});
